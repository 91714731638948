<style scoped>
.filters {
  height: var(--filters-height);
  background: white;
}

/* Event Card List CSS */
.event-card-list {
  flex-grow: 1;
  overflow: auto;
  height: calc(100% - var(--toolbar-height));
}

.event-card-list_with_filters {
  height: calc(100% - var(--toolbar-height) - var(--filters-height));
}

.event-card-list-item {
  padding: 3px 2px;
}

.event-card {
  overflow: hidden;
  width: 100%;
  border-radius: 0px;
  border: 2px solid white;
  background-color: transparent !important;
}

.event-card .name {
  font-size: 1.1em;
  font-weight: 400;
}
.event-card .process_number {
  font-size: 0.7em;
}

.event-card .type,
.event-card .etd,
.event-card .eta {
  font-size: 0.7em;
  font-weight: 400;
  color: #6d6d6d;
}

.event-card .status,
.event-card .tag {
  width: 100%;
}

.event-card .tag i {
  font-size: 1.3em;
  margin-bottom: 0.1em;
}

.event-card .updated_at {
  font-size: 0.7em;
  font-weight: 400;
  color: #021727;
  margin: 5px;
  margin-left: 5px;
}

.selected .event-card {
  border: 2px solid #ebe70c !important;
  background-color: #f9f9e7 !important;
}

.vscalls {
  height: calc(100%);
  overflow: hidden;
}
</style>

<template>
  <div ref="vscalls" class="vscalls">
    <v-toolbar color="default" dense fixed flat>
      <v-toolbar-title dense>
        <v-list-item class="ma-0 pa-0">
          <v-list-item-content class="ma-0 pa-0">
            <v-list-item-title class="font-weight-bold">
              {{ $t("permissions.vessel_calls") }} ({{
                resultsFiltered.length
              }}
              / {{ vesselCalls.length }})
            </v-list-item-title>
            <v-list-item-subtitle
              class="font-weight-regular"
              style="font-size: 11px"
              >{{ $t("global.date_from") }}:
              {{ this.range.start | formatJSFullDatetime }}
              {{ $t("global.date_to") }}
              {{ this.range.end | formatJSFullDatetime }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn depressed icon @click="filterOpen = !filterOpen">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>

    <v-expand-transition>
      <div class="pa-5 filters" v-show="filterOpen">
        <v-text-field
          :placeholder="$t('vessel_call.search_filter')"
          filled
          rounded
          dense
          v-model="searchText"
        ></v-text-field>

        <vc-date-picker v-model="range" is-range>
          <template v-slot="{ inputValue, inputEvents }">
            <v-row>
              <v-col cols="6" sm="6">
                <v-text-field
                  class="textField"
                  :label="$t('plan.start_date')"
                  outlined
                  dense
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  :masks="{ input: ['L', 'YYYY-MM-DD'] }"
                ></v-text-field>
              </v-col>

              <v-col cols="6" sm="6">
                <v-text-field
                  class="textField"
                  :label="$t('plan.end_date')"
                  outlined
                  dense
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  :masks="{ input: ['L', 'YYYY-MM-DD'] }"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </vc-date-picker>
        <v-select
          v-model="selectedStatuses"
          :items="statuses"
          :label="$t('global.situation')"
          multiple
          outlined
          class="mt-5"
          return-object
          :menu-props="{ offsetY: true }"
        >
          <template v-slot:selection="data">
            <v-chip
              :key="JSON.stringify(data.item.code)"
              :color="data.item.color"
              small
              dark
            >
              {{ data.item.text }}
            </v-chip>
          </template></v-select
        >
      </div>
    </v-expand-transition>

    <p class="text-center" v-if="resultsFiltered.length == 0">
      {{ $t("vessel_call.no_process_number_found") }}
    </p>

    <div
      :class="[
        filterOpen
          ? 'event-card-list event-card-list_with_filters'
          : 'event-card-list',
      ]"
    >
      <v-list-item-group
        v-model="vesselCall"
        :key="componentKey"
        color="blue"
        active-class="selected"
      >
        <v-list-item
          v-for="item in resultsFiltered"
          :key="item.id"
          :value="item"
          class="event-card-list-item"
          :class="{ selected: item && vesselCall && item.id === vesselCall.id }"
        >
          <v-card class="event-card pa-1">
            <v-row no-gutters>
              <v-col :cols="$vuetify.breakpoint.xs ? 12 : 3">
                <v-card flat width="!$vuetify.breakpoint.xs ? 400 : 140">
                  <VesselCallImage :height="125" :item="item" />
                </v-card>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xs ? 12 : 9">
                <v-row no-gutters class="pa-2">
                  <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
                    <h5 class="process_number">
                      {{ item.process_number }}
                    </h5>

                    <h1 class="name">
                      {{ item.name }}
                    </h1>

                    <h3 class="type">
                      {{ item.type }}
                    </h3>

                    <h3 v-if="!!item.has_dangerous_good">
                      <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="red"
                            small
                            v-bind="attrs"
                            v-on="on"
                            class="pa-0"
                          >
                            mdi-radioactive
                          </v-icon>
                        </template>
                        <span>{{ $t('vessel_call.has_dangerous_good') }}</span>
                      </v-tooltip>
                    </h3>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
                    <v-chip
                      v-if="item.status"
                      :color="item.status | formatVesselCallStatusColor"
                      text-color="white"
                      label
                      x-small
                      class="status"
                    >
                      {{ item.status | formatVesselCallStatus | toUpperCase }}
                    </v-chip>

                    <v-chip
                      v-if="item.eta"
                      outlined
                      x-small
                      label
                      class="tag"
                      :color="item.status | formatVesselCallETAColor"
                      >{{ $t('vessel_call.eta') }}:
                      {{ item.eta | formatDatetime }}
                    </v-chip>
                    <v-chip v-if="item.etd" outlined x-small label class="tag"
                      >{{ $t('vessel_call.etd') }}:
                      {{ item.etd | formatDatetime }}
                    </v-chip>
                    <v-chip
                      v-if="item.upcoming_berth_location"
                      outlined
                      x-small
                      label
                      class="tag"
                    >
                      <v-icon left> mdi-map-marker-radius </v-icon>
                      {{ item.upcoming_berth_location }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <div>
                  <p class="updated_at">
                    <b class="ml-1">{{ $t('plan.updated_at') }}: </b>
                    {{ item.updated_at | formatDatetime }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-list-item>
      </v-list-item-group>
    </div>
  </div>
</template>

<script>
/* Vessel calls socket*/
const STATUSES = configs.getVesselCallStatuses();
const DEFAULT_MAX_DAYS_TO_CONSIDER = 7;
import { Settings, DateTime } from "luxon";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();

import VesselCallImage from "@/components/VesselCalls/VesselCallImage";
export default {
  props: ["item", "records"],
  components: {
    VesselCallImage,
  },
  data() {
    return {
      selectedStatuses: STATUSES.map((item) => {
        return { text: item.text, value: item.code, color: item.color };
      }),
      searchText: "",
      filterOpen: false,
      statuses: STATUSES.map((item) => {
        return { text: item.text, value: item.code, color: item.color };
      }),
      componentKey: 0,
      range: {
        start: new Date(),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + DEFAULT_MAX_DAYS_TO_CONSIDER
        ),
      },
    };
  },
  watch: {
    vesselCall() {
      this.forceUpdate();
    },
  },
  computed: {
    vesselCall: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    vesselCalls() {
      return this.records;
    },
    resultsFiltered() {
      return JSON.parse(JSON.stringify(this.vesselCalls))
        .filter((item) => {
          /* Filter By Text */
          let search = (this.searchText ? this.searchText : "").toLowerCase();

          let resultFilterText =
            (item.name
              ? item.name.toLowerCase().indexOf(search) > -1
              : false) ||
            (item.imo ? item.imo.toLowerCase().indexOf(search) > -1 : false) ||
            (item.upcoming_berth_location
              ? item.upcoming_berth_location.toLowerCase().indexOf(search) > -1
              : false) ||
            (item.type
              ? item.type.toLowerCase().indexOf(search) > -1
              : false) ||
            (item.process_number
              ? item.process_number.toLowerCase().indexOf(search) > -1
              : false);

          /* Filter By Text */
          let resultFilterStatus = this.selectedStatuses
            .map((elem) => elem.value)
            .includes(item.status);
          let filterDateStatus =
            DateTime.fromISO(item.eta) <=
              DateTime.fromJSDate(this.range.end).endOf("day") &&
            DateTime.fromJSDate(this.range.start).startOf("day") <=
              DateTime.fromISO(item.etd);
          return resultFilterText && resultFilterStatus && filterDateStatus;
        })
        .sort(function(a, b) {
          return new Date(a.eta) - new Date(b.eta);
        });
    },
  },
  filters: {
    formatVesselCallStatus: function(code) {
      if (!code) return "";
      return STATUSES.find(function(data) {
        return data.code == code;
      }).text;
    },
    formatVesselCallStatusColor: function(code) {
      if (!code) return "";
      return STATUSES.find(function(data) {
        return data.code == code;
      }).color;
    },
    formatVesselCallETAColor: function(code) {
      if (!code) return "";
      return STATUSES.find(function(data) {
        return data.code == code;
      }).color;
    },
    toUpperCase: function(value) {
      return value.toUpperCase();
    },
  },

  methods: {
    forceUpdate() {
      this.componentKey += 1;
    },
  },
};
</script>
