<template>
  <div>
    <v-app-bar
      app
      color="light-blue darken-4"
      dense
      dark
      clipped-right
      clipped-left
    >
      <a href="#/map" rel="noopener">
        <v-img
          class="mx-1"
          src="@/assets/logo.png"
          max-height="40"
          max-width="40"
          contain
        ></v-img>
      </a>

      <v-toolbar-title>
        <v-list-item>
          <v-list-item-content>
            <div>
              <p class="mt-1 mb-1 pa-0" style="font-size: 18px">
                <b>3NavPlanning</b>
              </p>
              <p class="mt-1 mb-1 pa-0" style="font-size: 12px">
                {{ locode.name }}
              </p>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <Weather class="pa-1 mt-0" v-if="!$vuetify.breakpoint.xs" />

      <v-divider vertical v-if="isMain"></v-divider>

      <v-switch
        v-model="vesselCallsOpen"
        inset
        class="pa-4 mt-6"
        color="white"
        v-if="isMain"
      >
        <template v-slot:label>
          <span v-if="!isMobileDevice"> Mostrar Escalas </span>
          <v-icon v-else>mdi-vessel</v-icon>
        </template>
      </v-switch>

      <v-divider vertical v-if="isMain"></v-divider>

      <div class="pa-5" v-if="isMain">
        <v-badge
          color="red"
          overlap
          dot
          :value="notifications && notifications.length > 0"
        >
          <v-icon
            :color="!notificationsOpen ? 'white darken-2' : 'yellow darken-2'"
            @click="notificationsOpen = !notificationsOpen"
          >
            mdi-bell
          </v-icon>
        </v-badge>
      </div>

      <v-divider vertical></v-divider>

      <v-app-bar-nav-icon
        @click.stop="menuOpen = !menuOpen"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      disable-resize-watcher
      temporary
      v-model="menuOpen"
      app
      clipped
      right
      :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }"
      :width="400"
    >
      <Menu />
    </v-navigation-drawer>

    <v-navigation-drawer
      disable-resize-watcher
      v-model="detailOpen"
      app
      clipped
      :permanent="!!vesselCall || !!aisShip"
      :style="{ top: $vuetify.application.top + 'px', zIndex: 1 }"
      :width="400"
    >
      <DetailVesselCall
        v-show="!!vesselCall"
        :item.sync="vesselCall"
        :key="'VC' + vesselCallComponentKey"
      />
      <DetailAisShip
        v-show="!!aisShip"
        :ship.sync="aisShip"
        :key="'AS' + aisShipComponentKey"
      />
    </v-navigation-drawer>

    <v-navigation-drawer
      disable-resize-watcher
      v-model="vesselCallsOpen"
      app
      clipped
      right
      :style="{ top: $vuetify.application.top + 'px', zIndex: 2 }"
      :width="500"
      :permanent="vesselCallsOpen"
      v-if="isMain"
    >
      <VesselCallsList
        :item.sync="vesselCall"
        :records.sync="records"
        v-if="!!vesselCallsOpen"
      />
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="notificationsOpen"
      absolute
      right
      :style="{ top: $vuetify.application.top + 'px', zIndex: 3 }"
      :width="500"
      :permanent="notificationsOpen"
      v-if="isMain"
    >
      <NotificationsList :records.sync="notifications" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import Menu from "@/components/Main/Menu";
import Weather from "@/components/Main/Weather";
import VesselCallsList from "@/components/VesselCalls/List";
import DetailVesselCall from "@/components/VesselCalls/DetailVesselCall";
import DetailAisShip from "@/components/AisShips/DetailAisShip";
import NotificationsList from "@/components/Notifications/List";

import configs from "@/helpers/configs";

export default {
  props: ["item", "ship"],
  components: {
    Weather,
    VesselCallsList,
    DetailVesselCall,
    DetailAisShip,
    Menu,
    NotificationsList,
  },
  computed: {
    isMain() {
      return this.$route.name.toLowerCase() == "map";
    },
    vesselCalls() {
      return this.$store.state.vessel_calls.all;
    },
    notifications() {
      return this.$store.state.notifications.all;
    },
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile;
    },
    detailOpen: {
      get() {
        return this.aisShip != null || this.vesselCall != null;
      },
      set(val) {
        return val;
      },
    },
    aisShipListOpen: {
      get() {
        return this.$vuetify.breakpoint.xs;
      },
      set(val) {
        return val;
      },
    },
    locode() {
      return configs.getLocode();
    },
    vesselCall: {
      get() {
        return this.$store.state.vessel_calls.selected;
      },
      set(val) {
        this.$store.commit("vessel_calls/setSelected", val);
      },
    },
    aisShip: {
      get() {
        return this.$store.state.ais_ships.selected;
      },
      set(val) {
        this.$store.commit("ais_ships/setSelected", val);
      },
    },
  },
  data() {
    return {
      records: [],
      menuOpen: false,
      vesselCallsOpen: false,
      notificationsOpen: false,
      aisShipComponentKey: 0,
      vesselCallComponentKey: 0,
    };
  },
  watch: {
    vesselCalls() {
      this.records = this.vesselCalls.map((vcall) => {
        return {
          ...vcall,
          visible:
            vcall.end_mooring_bow && vcall.end_mooring_stern ? true : false,
        };
      });
    },
    vesselCall(val) {
      if (val) this.aisShip = null;
      this.forceRerender();
    },
    aisShip(val) {
      if (val) this.vesselCall = null;
      this.forceRerender();
    },
  },
  methods: {
    forceRerender() {
      this.aisShipComponentKey += 1;
      this.vesselCallComponentKey += 1;
    },
  },
};
</script>
<style>
.splitpanes.default-theme .splitpanes__pane {
  background-color: white !important;
}
</style>
<style scoped>
.main-content {
  height: calc(100vh - var(--toolbar-height));
  position: relative;
  overflow: hidden;
}

.pane-content {
  height: calc(100vh - var(--toolbar-height));
}
</style>
