<template>
  <div v-if="!!vesselCall" class="vscall">
    <v-toolbar dense fixed flat>
      <v-toolbar-title class="font-weight-bold">{{
        vesselCall.process_number
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed icon @click="vesselCall = null">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>

    <v-card class="mx-auto event-card" tile flat>
      <template slot="progress">
        <v-progress-linear
          color="blue darken-4"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-avatar
        class="photo pa-2 mt-2"
        color="blank"
        height="250"
        width="100%"
        tile
      >
        <v-img :src.sync="vesselCall.urlPhoto" :lazy-src="vesselCall.lazyPhoto">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-avatar>

      <v-card-title class="pa-2 pt-2">{{ vesselCall.name }}</v-card-title>

      <v-card-text class="ma-0 pa-1 pb-0 detail">
        <v-list class="transparent" style="padding-top: 0px">
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("global.state")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              <v-chip
                v-if="vesselCall.status"
                :color="vesselCall.status | formatVesselCallStatusColor"
                text-color="white"
                label
                x-small
                class="status"
              >
                {{ vesselCall.status | formatVesselCallStatus | toUpperCase }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium"
              >ETA</v-list-item-title
            >
            <v-list-item-subtitle
              :class="vesselCall.status | formatVesselCallETAColor"
            >
              {{ vesselCall.eta | formatDatetime }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium"
              >ETD</v-list-item-title
            >
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.etd | formatDatetime }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <div v-if="!!vesselCall.ata">
            <v-list-item>
              <v-list-item-title class="font-weight-medium"
                >ATA</v-list-item-title
              >
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.ata | formatDatetime }}
              </v-list-item-subtitle> </v-list-item
            ><v-divider class="ma-1"></v-divider>
          </div>
          <div v-if="!!vesselCall.atd">
            <v-list-item>
              <v-list-item-title class="font-weight-medium"
                >ATD</v-list-item-title
              >
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.atd | formatDatetime }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider class="ma-1"></v-divider>
          </div>
          <v-list-item>
            <v-list-item-title class="font-weight-medium"
              >IMO</v-list-item-title
            >
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.imo }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <div
            v-if="
              vesselCall.vessel_call_ship_particulars &&
              vesselCall.vessel_call_ship_particulars.mmsi
            "
          >
            <v-list-item>
              <v-list-item-title class="font-weight-medium"
                >MMSI</v-list-item-title
              >
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.vessel_call_ship_particulars.mmsi }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider class="ma-1"></v-divider>
          </div>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("global.countermark")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.customs_ship }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("global.local")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right text-wrap">
              {{ vesselCall.upcoming_berth_location }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.end_mooring_bow")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right text-wrap">
              {{ vesselCall.end_mooring_bow }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.end_mooring_stern")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right text-wrap">
              {{ vesselCall.end_mooring_stern }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.mooring_edge")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right text-wrap">
              {{ vesselCall.manoeuvre_starboard ? "Estibordo" : "Bombordo" }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <div v-if="vesselCall.entry_bow_draught">
            <v-list-item>
              <v-list-item-title class="font-weight-medium">{{
                $t("vessel_call.entry_bow_draught")
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.entry_bow_draught | formatNumber }}
                {{ $t("global.meters") }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider class="ma-1"></v-divider>
          </div>

          <div v-if="vesselCall.exit_bow_draught">
            <v-list-item>
              <v-list-item-title class="font-weight-medium">{{
                $t("vessel_call.exit_bow_draught")
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.exit_bow_draught | formatNumber }}
                {{ $t("global.meters") }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider class="ma-1"></v-divider>
          </div>
          <div v-if="vesselCall.entry_stern_draught">
            <v-list-item>
              <v-list-item-title class="font-weight-medium">{{
                $t("vessel_call.entry_stern_draught")
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.entry_stern_draught | formatNumber }}
                {{ $t("global.meters") }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider class="ma-1"></v-divider>
          </div>
          <div v-if="vesselCall.exit_stern_draught">
            <v-list-item>
              <v-list-item-title class="font-weight-medium">{{
                $t("vessel_call.exit_stern_draught")
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.exit_stern_draught | formatNumber }}
                {{ $t("global.meters") }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider class="ma-1"></v-divider>
          </div>
          <div v-if="vesselCall.call_sign">
            <v-list-item>
              <v-list-item-title class="font-weight-medium">{{
                $t("vessel_call.call_sign")
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.call_sign }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-divider class="ma-1"></v-divider>
          </div>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.shipping_agent")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right text-wrap">
              {{ vesselCall.agent_name }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.previous_port")
            }}</v-list-item-title>
            <v-list-item-subtitle
              class="text-right text-wrap"
              v-if="!!vesselCall.origin_port"
            >
              {{ vesselCall.origin_port }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.next_port")
            }}</v-list-item-title>
            <v-list-item-subtitle
              class="text-right text-wrap"
              v-if="!!vesselCall.destination_port"
            >
              {{ vesselCall.destination_port }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>

          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.gross_tonnage")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.gt | formatNumber }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium"
              >CEP (LBP)</v-list-item-title
            >
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.lbp | formatNumber }}
              {{ $t("global.meters") }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <div v-if="vesselCall.loa">
            <v-list-item>
              <v-list-item-title class="font-weight-medium"
                >LOA (CFF)</v-list-item-title
              >
              <v-list-item-subtitle class="text-right">
                {{ vesselCall.loa | formatNumber }}
                {{ $t("global.meters") }}
              </v-list-item-subtitle> </v-list-item
            ><v-divider class="ma-1"></v-divider>
          </div>
          <v-list-item>
            <v-list-item-title class="font-weight-medium"
              >Beam</v-list-item-title
            >
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.beam | formatNumber }}
              {{ $t("global.meters") }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.draught_ship")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.maximum_draught | formatNumber }}
              {{ $t("global.meters") }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.captain")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.captain }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.crew_number")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.num_crew_members | formatNumber }}
            </v-list-item-subtitle> </v-list-item
          ><v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.has_dangerous_good")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right text-wrap">
              {{
                vesselCall.has_dangerous_good
                  ? $t("global.yes")
                  : $t("global.no")
              }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("global.observations")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right text-wrap">
              {{
                vesselCall.observations ||
                vesselCall.entry_remarks ||
                vesselCall.exit_remarks
              }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
        </v-list>
      </v-card-text>

      <v-card-text
        class="pa-2 ma-0 pb-2 pt-0"
        v-if="vesselCall.events && vesselCall.events.length > 0"
      >
        <v-btn depressed
          color="primary"
          class="ma-0"
          dark
          block
          elevation="0"
          @click.stop="showEvents = !showEvents"
        >
          {{ $t("vessel_call.maneuvers_reporting_points") }}
        </v-btn>
        <v-dialog
          v-model="showEvents"
          transition="dialog-bottom-transition"
          width="600"
          scrollable
        >
          <v-card tile>
            <v-toolbar dark flat color="blue darken-4">
              <v-toolbar-title>
                <v-list-item class="ma-0 pa-0 ml-1">
                  <v-list-item-content class="ma-0 pa-0">
                    <v-list-item-title class="font-weight-bold">
                      {{ vesselCall.process_number }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="font-weight-regular"
                      style="font-size: 11px"
                      >{{ $t("vessel_call.maneuvers_and_reporting_points") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item></v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn depressed icon dark @click.stop="showEvents = !showEvents">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pa-5">
              <Events :item.sync="vesselCall" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>

      <v-card-text class="pa-2 ma-0 pb-0 pt-0">
        <v-btn depressed
          color="grey"
          class="ma-0"
          dark
          block
          elevation="0"
          @click.stop="showChartDraught = !showChartDraught"
          v-if="
            !!vesselCall.end_mooring_stern &&
            !!vesselCall.end_mooring_bow &&
            !!vesselCall.eta &&
            !!vesselCall.etd
          "
        >
          {{ $t("vessel_call.view_draught_and_tides") }}
        </v-btn>
        <v-dialog
          v-model="showChartDraught"
          transition="dialog-bottom-transition"
          width="60%"
          content-class="chartDialog"
        >
          <v-card tile>
            <v-toolbar dark flat color="blue darken-4">
              <v-toolbar-title>
                <v-list-item class="ma-0 pa-0 ml-1">
                  <v-list-item-content class="ma-0 pa-0">
                    <v-list-item-title
                      class="font-weight-bold"
                      style="font-size: 14px"
                    >
                      {{ vesselCall.process_number }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="font-weight-regular"
                      style="font-size: 12px"
                      >{{ $t("vessel_call.stay_draught_tides") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item></v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn depressed
                icon
                dark
                @click.stop="showChartDraught = !showChartDraught"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pa-5">
              <ChartDraught :item.sync="vesselCall" />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>

      <v-card-text class="ma-0 pa-0 detail">
        <v-list class="transparent">
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.updated_at")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              {{ vesselCall.updated_at | formatDatetime }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list></v-card-text
      >
    </v-card>
  </div>
</template>

<script>
import configs from "@/helpers/configs";
const STATUSES = configs.getVesselCallStatuses();
import { shipsService } from "@/api_services/ships.service";
import ChartDraught from "@/components/VesselCalls/ChartDraught";
import Events from "@/components/VesselCalls/Events";
export default {
  props: ["item"],

  data: () => ({
    showEvents: false,
    showChartDraught: false,
    image: null,
  }),
  components: {
    ChartDraught,
    Events,
  },
  computed: {
    vesselCall: {
      get() {
        if (this.item) {
          return {
            ...this.item,
            urlPhoto: "/api/v1/ships/" + (this.item.imo ?? 0) + "/photo/" + this.item.port_locode,
            lazyPhoto: shipsService.getLazyPhoto(),
          };
        } else return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
  },
  filters: {
    formatVesselCallStatus: function (code) {
      if (!code) return "";
      return STATUSES.find(function (data) {
        return data.code == code;
      }).text;
    },
    formatVesselCallStatusColor: function (code) {
      if (!code) return "";
      return STATUSES.find(function (data) {
        return data.code == code;
      }).color;
    },
    formatVesselCallETAColor: function (code) {
      if (!code) return "";
      return (
        STATUSES.find(function (data) {
          return data.code == code;
        }).color + "--text text-right"
      );
    },
    toUpperCase: function (value) {
      return value.toUpperCase();
    },
  },
};
</script>
<style>
.detail .v-list-item .v-list-item__title,
.detail .v-list-item .v-list-item__subtitle {
  font-size: 12px !important;
}

.detail .v-list-item {
  min-height: 14px;
  padding: 0px 6px;
}

.event-card {
  flex-grow: 1;
  overflow: auto;
  height: calc(100% - 50px);
}

.chartDialog {
  overflow: hidden !important;
}

.vscall {
  height: calc(100%);
  overflow: hidden;
}

.vscall .v-toolbar__content {
  padding-left: 10px;
}
</style>
