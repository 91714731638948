<template>
  <div v-if="aisShip" class="aisship">
    <v-toolbar dense fixed flat>
      <v-toolbar-title>
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-layout justify align-center class="ml-1">
              <span v-bind="attrs" v-on="on" left v-if="!!aisShip.countryCode">
                <country-flag :country="aisShip.countryCode" rounded />
              </span>
              <div class="pt-1 ml-3 font-weight-bold">
                {{ aisShip.shipname || aisShip.mmsi }}
              </div>
            </v-layout>
          </template>
          <span v-if="!!aisShip.countryName">{{ aisShip.countryName }}</span>
        </v-tooltip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>

    <v-card class="mx-auto event-card" tile flat>
      <template slot="progress">
        <v-progress-linear
          color="blue darken-4"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <div>
        <v-avatar
          class="photo pa-2 mt-2"
          color="blank"
          height="250"
          width="100%"
          tile
        >
          <v-img :src.sync="aisShip.urlPhoto" :lazy-src="aisShip.lazyPhoto">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
      </div>

      <v-card-text class="ma-0 pa-1 pb-0 detail" v-if="!!aisShip">
        <v-list>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.ship_type")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              <v-chip
                x-small
                label
                :color="aisShip.color"
                text-color="white"
                class="ml-4"
                dark
                >{{ aisShip.type }}</v-chip
              ></v-list-item-subtitle
            >
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.message_type")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.aistype
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.channel")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.channel
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.mmsi")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.mmsi
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.country")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.countryName
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.type_code")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.cargo
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.velocity")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.sog
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.lat")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.lat
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.lon")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.lon
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.course")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.cog
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("vessel_call.hdg")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.hdg
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.length")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.length
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.width")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.width
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.call_sign")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.callsign
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
          <v-list-item>
            <v-list-item-title class="font-weight-medium">{{
              $t("ais_ship.updated_at")
            }}</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              aisShip.updatedAt | formatDatetime
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-divider class="ma-1"></v-divider>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import configs from "@/helpers/configs";
import { shipsService } from "@/api_services/ships.service";
export default {
  props: ["ship"],
  computed: {
    port_locode() {
      return configs.getLocode();
    },
    aisShip: {
      get() {
        if (this.ship) {
          let itemShip = this.$store.state.ais_ships.all.find((ship) => {
            return ship.id == this.ship.id;
          });
          let type = configs.getShipType(itemShip.cargo);
          return {
            ...itemShip,
            type: type.name,
            color: type.color,
            urlPhoto:
              "/api/v1/ships/" +
              (itemShip.imo ?? 0) +
              "/photo/" +
              this.port_locode.code,
            lazyPhoto: shipsService.getLazyPhoto(),
          };
        } else return null;
      },
      set(val) {
        this.$emit("update:ship", val);
      },
    },
  },
  methods: {
    close() {
      this.aisShip = null;
    },
  },
};
</script>
<style>
.detail .v-list-item .v-list-item__title,
.detail .v-list-item .v-list-item__subtitle {
  font-size: 12px !important;
}

.detail .v-list-item {
  min-height: 14px;
  padding: 0px 6px;
}

.event-card {
  flex-grow: 1;
  overflow: auto;
  height: calc(100% - 50px);
}

.aisship {
  height: calc(100%);
  overflow: hidden;
}
.aisship .v-toolbar__content {
  padding-left: 5px;
}
</style>
