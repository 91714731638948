<template>
  <div
    ref="menus"
    class='menus d-flex flex-column'
  >
    <div class="flex-grow-0" v-if="user">
      <v-list-item class="pa-1 primary" dark >
        <v-list-item-avatar class="ml-2 mr-2">
          <v-btn depressed color="black" fab small>
            <span class="white--text text-h6">{{ getNameChars() }}</span>
          </v-btn>
        </v-list-item-avatar>
        <v-list-item-content class="ma-0 pa-0" style="width: 100%">
          <v-list-item-title class="font-weight-bold white--text">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle
            class="font-weight-regular white--text"
            style="font-size: 11px"
            >{{ user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-divider></v-divider>

    <div class="overflow-y-auto flex-grow-1">
      <v-list-group
        class="ports-group"
        v-model="ports.active"
        no-action
        v-if="userLocodes && userLocodes.length >= 1"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <b class="item-title">{{
                $t("global.ports_locodes").toUpperCase()
              }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, index) in userLocodes"
          class="pl-4"
          style="background-color: white"
          :key="index"
          @click="openLocode(item)"
          dense
          :class="locode.name === item.name ? 'item-selected' : ''"
        >
          <v-list-item-action class="mr-2">
            <v-icon class="item-icon">mdi-anchor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <b class="item-title">{{ item.name }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-list-group>

      <v-list-group
        class="navplanning-group"
        v-model="navplanning.active"
        no-action
        v-if="navplanning_items.length > 0"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <b class="item-title">3NAV - {{ locode.name.toUpperCase() }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, index) in navplanning_items"
          :key="index"
          class="pl-4"
          style="background-color: white"
          :to="item.route"
          rel="noopener"
          active-class="item-selected"
        >
          <v-list-item-action class="mr-2">
            <v-icon class="item-icon">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              ><b class="item-title">{{ item.title }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-list-group>

      <v-list-group
        class="configurations-group"
        v-model="configurations.active"
        no-action
        v-if="configurations_items.length > 0"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              ><b class="item-title">{{ configurations.title }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, index) in configurations_items"
          :key="index"
          class="pl-4"
          style="background-color: white"
          :to="item.route"
          rel="noopener"
          active-class="item-selected"
        >
          <v-list-item-action class="mr-2">
            <v-icon class="item-icon">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              ><b class="item-title">{{ item.title }}</b>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-list-group>

      <v-list-item rel="noopener" @click.stop="logout" dense>
        <v-list-item-action class="mr-2">
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <b>{{ $t("global.logout") }}</b>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import configs from "@/helpers/configs";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export default {
  name: "Profile",
  data() {
    return {
      user: null,
      ports: {
        active: true,
      },
      navplanning: {
        title: "",
        icon: "",
        active: true,
        items: [
          {
            title: this.$t("global.map"),
            icon: "mdi-map",
            route: "/map",
            permission: "MAP",
          },
        ],
      },
      configurations: {
        icon: "mdi-cog",
        active: true,
        items: [
          {
            title: this.$t("configurations.mooring_bitts"),
            icon: "mdi-window-shutter-open",
            route: "/mooring_bitts",
            permission: "MOORING_BITTS",
          },
          {
            title: this.$t("configurations.zones"),
            icon: "mdi-square-rounded-outline",
            route: "/zones",
            permission: "ZONES",
          },
          {
            title: this.$t("configurations.layers"),
            icon: "mdi-layers",
            route: "/layers",
            permission: "LAYERS",
          },
          {
            title: this.$t("configurations.users"),
            icon: "mdi-account-group",
            route: "/users",
            permission: "USERS",
          },
          {
            title: this.$t("configurations.ship_types"),
            icon: "mdi-anchor",
            route: "/ship_types",
            permission: "SHIP_TYPES",
          },
          {
            title: this.$t("configurations.bathymetry"),
            icon: "mdi-dots-square",
            route: "/bathymetry",
            permission: "HYDRO",
          },
          {
            title: this.$t("configurations.tides"),
            icon: "mdi-waves",
            route: "/tides",
            permission: "HYDRO",
          },
          {
            title: this.$t("configurations.ships"),
            icon: "mdi-ship-wheel",
            route: "/ships",
            permission: "SHIPS",
          },
          {
            title: this.$t("configurations.audit_logs"),
            icon: "mdi-server-security",
            route: "/audit_logs",
            permission: "AUDIT_LOGS",
          },
        ],
        title: this.$t("global.configurations").toUpperCase(),
      },
    };
  },
  created() {
    this.fetchUserData();
  },
  computed: {
    userLocodes() {
      return configs
        .getLocodes()
        .filter((item) => sharedHelpers.getUserLocodes().includes(item.code));
    },
    locode() {
      return configs.getLocode();
    },
    configurations_items() {
      return this.configurations.items.filter((configuration) => {
        return configuration.permission
          ? this.isAdminUser ||
              (this.hasUserPermissionToViewOrManage(configuration.permission) &&
                this.isManagerUser)
          : true;
      });
    },
    navplanning_items() {
      return this.navplanning.items.filter((navplanning) => {
        return navplanning.permission
          ? this.isAdminUser ||
              (this.hasUserPermissionToViewOrManage(navplanning.permission) &&
                this.isManagerUser)
          : true;
      });
    },
    userAccount() {
      return this.$store.state.user.account;
    },
    userType() {
      return this.userAccount && this.userAccount.user
        ? this.userAccount.user.type
        : "";
    },
    isAdminUser() {
      return this.userType == configs.getAdminUserType();
    },
    isManagerUser() {
      return this.userType == configs.getManagerUserType();
    },
  },
  methods: {
    getNameChars() {
      return this.userAccount && this.userAccount.user ? this.userAccount.user.name.substring(0, 2) : "";
    },
    fetchUserData() {
      let account = JSON.parse(
        sharedHelpers.getStorageItemWithExpiry("account")
      );
      this.user = account.user;
    },
    openLocode(locode) {
      sharedHelpers.setStorageItemWithExpiry(
        "locode",
        locode,
        8640000 //24hours
      );

      if (this.$route.name.toUpperCase() === "MAP") {
        location.reload();
      } else {
        this.$router.push("/map");
      }

      this.$emit("loadNotifications");
    },
    logout() {
      this.$router.push("/login", () => {
        this.$store.dispatch("LOGOUT").then(() => {});
      });
    },
  },
};
</script>
<style scoped>
.item-selected,
.item-selected::before {
  background: transparent;
}
.item-selected .item-title {
  font-weight: 900;
  color: rgba(0, 0, 0, 0.87);
}
.item-selected .item-icon {
  color: black;
}

.item-title {
  font-size: 0.8125rem;
  line-height: 1rem;
}

.ports-group,
.configurations-group,
.navplanning-group {
  background-color: #eeeeee;
  color: black !important;
  border-color: #eeeeee;
}
.menus {
  height: calc(100dvh - var(--toolbar-height));
  overflow: auto;
}
</style>
