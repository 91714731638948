<template>
  <v-card-text class="ma-0 pa-0 events" v-if="item.events">
    <v-timeline align-top dense>
      <v-timeline-item
        :color="event.tag == 'manoeuvre' ? 'grey' : 'black'"
        v-for="(event, index) in item.events"
        :key="index"
        dense
      >
        <span slot="icon" class="white--text">{{
          event.tag == "manoeuvre" ? "M" : "P"
        }}</span>
        <v-card dense outlined tile>
          <v-card-subtitle class="text-caption ma-0 pa-3 pb-0">
            {{ event.datetime | formatDatetime }}
          </v-card-subtitle>
          <v-card-title
            class="text-caption font-weight font-weight-bold ma-0 pa-3 pt-0"
          >
            {{ event.type }}
          </v-card-title>
          <v-card-text class="ma-0 pa-0 text-caption">
            <v-list class="transparent">
              <v-divider class="mx-4 ma-1" v-if="!!event.status"></v-divider>
              <v-list-item v-if="!!event.status" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("global.state")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ event.status }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider class="mx-4 ma-1" v-if="!!event.location"></v-divider>
              <v-list-item v-if="!!event.location" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("global.local")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ event.location }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider
                class="mx-4 ma-1"
                v-if="!!event.mooring_bow_code"
              ></v-divider>
              <v-list-item v-if="!!event.mooring_bow_code" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("vessel_call.end_mooring_bow")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ event.mooring_bow_code }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider
                class="mx-4 ma-1"
                v-if="!!event.mooring_stern_code"
              ></v-divider>
              <v-list-item v-if="!!event.mooring_stern_code" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("vessel_call.end_mooring_stern")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ event.mooring_stern_code }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider class="mx-4 ma-1" v-if="!!event.starboard"></v-divider>
              <v-list-item v-if="!!event.starboard" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("vessel_call.mooring_edge")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ event.starboard ? "Estibordo" : "Bombordo" }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider class="mx-4 ma-1" v-if="!!event.tugs"></v-divider>
              <v-list-item v-if="!!event.tugs" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("vessel_call.tows")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ event.tugs }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider
                class="mx-4 ma-1"
                v-if="!!event.observations"
              ></v-divider>
              <v-list-item v-if="!!event.observations" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("global.observations")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right text-wrap">
                  {{ event.observations | toUpperCase }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider
                class="mx-4 ma-1"
                v-if="!!event.version_date"
              ></v-divider>
              <v-list-item v-if="!!event.version_date" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("ais_ship.updated_at")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ event.version_date | formatDatetime }}
                </v-list-item-subtitle>
              </v-list-item>
              <v-divider
                class="mx-4 ma-1"
                v-if="!!event.version_user"
              ></v-divider>
              <v-list-item v-if="!!event.version_user" class="ma-0 pl-3">
                <v-list-item-title class="font-weight-medium">{{
                  $t("global.changed_by")
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ event.version_user }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card-text>
</template>

<script>
export default {
  props: ["item"],
  data: () => ({}),
  filters: {
    toUpperCase: function (value) {
      return value.toUpperCase();
    },
  },
};
</script>
<style lang="scss">
.v-timeline-item__divider {
  min-width: 34px;
  margin-right: 18px;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 34px) !important;
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  left: calc(17px - 1px);
}
.events .v-list-item .v-list-item__title,
.events .v-list-item .v-list-item__subtitle {
  font-size: 12px !important;
}
.events .v-list-item {
  min-height: 14px;
}
</style>
