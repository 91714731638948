<template>
  <v-avatar class="photo" color="grey" :height="height" :width="120" tile>
    <v-img class="grey lighten-2" :src="urlPhoto" :lazy-src="lazyPhoto">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-avatar>
</template>
<script>
import { shipsService } from "@/api_services/ships.service";
export default {
  props: ["height", "item"],
  computed: {
    lazyPhoto() {
      return this.getLazyPhoto();
    },
    vesselCall: {
      get() {
        return this.item;
      },
      set(val) {
        this.$emit("update:item", val);
      },
    },
    urlPhoto() {
      return "/api/v1/ships/" + (this.vesselCall.imo ?? 0) + "/photo/" + this.vesselCall.port_locode;
    },
  },
  methods: {
    getLazyPhoto() {
      return shipsService.getLazyPhoto();
    },
  },
};
</script>
