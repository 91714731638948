<style scoped>
.filters {
  height: var(--filters-notification-height);
  background: white;
}

.notifications {
  height: calc(100% - var(--toolbar-height));
  overflow: hidden;
}

.event-card-list_with_filters {
  height: calc(
    100% - var(--toolbar-height) - var(--filters-notification-height)
  ) !important;
}
.event-card-list {
  overflow: auto;
  height: calc(100% - var(--toolbar-height));
  padding: 5px;
}

.event-card-list-item {
  border: 0px !important;
  border-bottom: 1px solid rgb(214, 211, 211) !important;
  border-radius: 0px;
  margin: 0px;
}

.event-card-list .name {
  font-size: 1.1em !important;
}

.event-tag {
  font-size: 12px !important;
  line-height: 20px !important;
}
</style>

<template>
  <div ref="notifications" class="notifications">
    <v-toolbar color="default" dense flat>
      <v-toolbar-title class="font-weight-bold"
        >{{ $t("notifications.notifications") }} ({{ notifications.length }})
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn depressed icon @click="filterOpen = !filterOpen">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-expand-transition>
      <div class="pa-5 pb-0 filters" v-show="filterOpen">
        <v-text-field
          :placeholder="$t('notifications.search_filter')"
          filled
          rounded
          dense
          v-model="searchText"
          hide-details
        ></v-text-field>

        <v-select
          v-model="selectedErrorTypes"
          :items="errorTypes"
          :label="$t('notifications.error_type')"
          multiple
          outlined
          class="mt-5"
          return-object
          :menu-props="{ offsetY: true }"
        >
          <template v-slot:selection="data">
            <v-chip
              :key="data.item.code"
              :color="parseColorNotification(data.item.value)"
              small
              dark
            >
              <v-icon left small>
                {{ parseIconNotification(data.item.value) }}
              </v-icon>
              {{ data.item.text }}
            </v-chip>
          </template></v-select
        >
      </div>
    </v-expand-transition>

    <p class="text-center" v-if="resultsFiltered.length == 0">
      {{ $t("notifications.no_notification_found") }}
    </p>

    <v-divider></v-divider>
    <div
      :class="[
        filterOpen
          ? 'event-card-list event-card-list_with_filters'
          : 'event-card-list',
      ]"
    >
      <v-alert
        v-for="item in resultsFiltered"
        :key="item.id"
        prominent
        :type="item.type"
        :icon="parseIconNotification(item.code)"
        outlined
        dark
        dense
        class="event-card-list-item"
        style="width:100%"
      >
        <h5 class="black--text text-uppercase">{{ item.process_number }}</h5>
        <h2 class="black--text ma-0 pa-0 name">{{ item.name }}</h2>
        <p class="text-caption black--text ma-0 pa-0 event-tag">
          <b>{{ $t("notifications.error_type") }}: </b
          >{{ parseMessageNotification(item.code) || item.code }}
        </p>
        <p class="text-caption black--text ma-0 pa-0 event-tag">
          <b>{{ $t("notifications.verified") }}: </b
          >{{ item.updatedAt | formatDatetime }}
        </p>
      </v-alert>
    </div>
  </div>
</template>

<script>
/* Vessel calls socket*/
import { Settings } from "luxon";
import configs from "@/helpers/configs";
Settings.defaultZone = configs.getTimezone();
Settings.defaultLocale = configs.getLocale();
const ERROR_TYPES = configs.getErrorTypes();

export default {
  props: ["records"],
  components: {},
  data() {
    return {
      searchText: "",
      selectedErrorTypes: ERROR_TYPES.map((item) => {
        return { text: item.text, value: item.code, type: item.type };
      }),
      errorTypes: ERROR_TYPES.map((item) => {
        return { text: item.text, value: item.code, icon: item.icon };
      }),
      filterOpen: false,
    };
  },
  watch: {},
  computed: {
    notifications() {
      return this.records.slice().sort((a, b) => {
        if (a.updatedAt > b.updatedAt) {
          return -1;
        }
      });
    },
    resultsFiltered() {
      return this.notifications.filter((item) => {
        /* Filter By Text */
        let search = (this.searchText ? this.searchText : "").toLowerCase();

        let resultFilterText =
          (item.name ? item.name.toLowerCase().indexOf(search) > -1 : false) ||
          (item.process_number
            ? item.process_number.toLowerCase().indexOf(search) > -1
            : false);

        /* Filter By Text */
        let resultFilterErrorTypes = this.selectedErrorTypes
          .map((elem) => elem.value)
          .includes(item.code);
        return resultFilterText && resultFilterErrorTypes;
      });
    },
  },
  methods: {
    parseMessageNotification(code) {
      let errType = this.findErrorType(code);
      return errType ? errType.text : "";
    },
    parseIconNotification(code) {
      let errType = this.findErrorType(code);
      return errType ? errType.icon : "";
    },
    parseColorNotification(code) {
      let errType = this.findErrorType(code);
      return errType ? errType.color : "#07EBE8"; //"#07EBE8" is a default color
    },
    findErrorType(code) {
      return ERROR_TYPES.find((errType) => {
        return errType.code === code;
      });
    },
  },
};
</script>
